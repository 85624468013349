.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 40px;
  left: 20px;
  z-index: 1000;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  border: 2px solid white;
  padding-left: 5.5px;
}

.scroll-to-top:hover {
  background-color: white;
}

.ads {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18px;
  padding: 10px 0; /* Add some padding if needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for a floating effect */
  display: flow-root;
  align-content: center;
  background-color: #f8f9fa;
}
